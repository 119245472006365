import { Injectable } from '@angular/core';
import {
    HeatPumpPlannerPlanningGetById,
    PhotovoltaicPlannerPlanningGetById,
    VariantGetById,
    VentilationPlannerPlanningGetById,
    ViGuidePlanningGetById
} from '@customer-apps/api-planning-projects';
import { defaultVariantNameKey, firstVariantNameKey, secondVariantNameKey, thirdVariantNameKey } from '@customer-apps/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { isMaxVariantQuantity, MAX_VARIANT_QUANTITY, CREATE_NEW_VARIANT_ID, getOrder } from '../../../shared/utils';
import {
    DuplicatePlanningOption,
    DuplicatePlanningOptionValue,
    HeatPumpPlanningWithTemplateAttributes,
    PhotovoltaicPlanningWithTemplateAttributes,
    VariantWithTemplateAttributes,
    VentilationPlanningWithTemplateAttributes,
    ViguidePlanningWithTemplateAttributes
} from '@customer-apps/shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class VariantsMapper {
    constructor(private translate: TranslateService) {}

    public toVariantsWithTemplateAttributes(variants: VariantGetById[]): VariantWithTemplateAttributes[] {
        return variants.map((variant, index) => ({
            ...variant,
            name: this.getName(index),
            plannings: this.toPlanningsWithTemplateAttributes({ variants, variantToSkipId: variant.id, plannings: variant.plannings }),
            duplicateVariantDisabled: isMaxVariantQuantity(variants.length)
        }));
    }

    private getName(index: number) {
        const variantNames: string[] = [firstVariantNameKey, secondVariantNameKey, thirdVariantNameKey];
        return this.translate.instant(variantNames[index]);
    }

    private getPlanningDuplicationOptions(options: {
        variants: VariantGetById[];
        variantToSkipId: string;
        planning:
            | HeatPumpPlannerPlanningGetById
            | PhotovoltaicPlannerPlanningGetById
            | VentilationPlannerPlanningGetById
            | ViGuidePlanningGetById;
    }): DuplicatePlanningOption[] {
        const { variants, variantToSkipId, planning } = options;

        const newVariantSource: DuplicatePlanningOptionValue = {
            id: CREATE_NEW_VARIANT_ID,
            newVariantRequest: {
                order: getOrder(variants),
                name: this.translate.instant(defaultVariantNameKey)
            }
        };

        const allVariantsHavePlanningOfThisAppId = variants.every(variantItem =>
            variantItem.plannings?.find(planningItem => planningItem.applicationId === planning.applicationId)
        );
        const hasLessVariantsThanMaxQuantity = variants.length < MAX_VARIANT_QUANTITY;
        const optionsSource =
            hasLessVariantsThanMaxQuantity && allVariantsHavePlanningOfThisAppId ? [...variants, newVariantSource] : variants;

        return optionsSource
            .map((variant, index) => ({
                value: {
                    id: variant.id,
                    newVariantRequest: (variant as DuplicatePlanningOptionValue).newVariantRequest
                },
                info: (variant as DuplicatePlanningOptionValue).newVariantRequest
                    ? this.translate.instant('VARIANT_TILE.CREATE_NEW_VARIANT_ON_DUPLICATION')
                    : null,
                label: this.getName(index)
            }))
            .filter(item => item.value.id !== variantToSkipId);
    }

    private toPlanningsWithTemplateAttributes(options: {
        variants: VariantGetById[];
        variantToSkipId: string;
        plannings?: (
            | HeatPumpPlannerPlanningGetById
            | PhotovoltaicPlannerPlanningGetById
            | VentilationPlannerPlanningGetById
            | ViGuidePlanningGetById
        )[];
    }):
        | (
              | HeatPumpPlanningWithTemplateAttributes
              | PhotovoltaicPlanningWithTemplateAttributes
              | VentilationPlanningWithTemplateAttributes
              | ViguidePlanningWithTemplateAttributes
          )[]
        | undefined {
        const { variants, variantToSkipId, plannings } = options;
        return plannings?.map(planning => ({
            ...planning,
            duplicationOptions: this.getPlanningDuplicationOptions({ variants, variantToSkipId, planning })
        }));
    }
}
